import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { useCallback, useContext, useEffect, useState } from 'react';

import { CaptchaActionTypes, Store } from '@src/store';

export const Captcha = () => {
  const {
    state: {
      stateCaptcha: { isCaptchaVisible },
    },
    dispatch,
  } = useContext(Store);
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleSuccess = useCallback(
    (token: string) => {
      dispatch({
        type: CaptchaActionTypes.SetCaptchaToken,
        payload: token,
      });
    },
    [dispatch]
  );

  const handleReset = useCallback(() => {
    dispatch({
      type: CaptchaActionTypes.SetIsCaptchaVisible,
      payload: false,
    });
  }, [dispatch]);

  const handleTokenExpired = useCallback(() => {
    dispatch({
      type: CaptchaActionTypes.SetCaptchaToken,
      payload: undefined,
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isCaptchaVisible) {
      setResetCaptcha((prev) => prev + 1);
    }
  }, [isCaptchaVisible]);

  return (
    <InvisibleSmartCaptcha
      key={resetCaptcha}
      // TODO: Вынести в env-keys.js
      sitekey="ysc1_T6R8K91WOQb3INdB53drRtQ8BBYzzo3YEOya5t8V0cdd268d"
      visible={isCaptchaVisible}
      hideShield={true}
      onSuccess={handleSuccess}
      onChallengeHidden={handleReset}
      onJavascriptError={handleReset}
      onNetworkError={handleReset}
      onTokenExpired={handleTokenExpired}
    />
  );
};
